<script lang="ts">
  import { people } from "./employees.ts";
</script>

<div class="so-widget-sow-editor so-widget-sow-editor-base">
  <h3 class="widget-title" style="color: rgb(68, 63, 63);">Nasz Zespół</h3>
  <div class="siteorigin-widget-tinymce textwidget">
    <div class="textwidget">
      <div class="tmm tmm_808">
        <div class="tmm_4_columns tmm_wrap tmm_plugin_f">
          <span class="tmm_two_containers_tablet" />
          <div class="tmm_container">
            {#each people as person}
              <div class="tmm_member" style="border-top:#dd0000 solid 5px;">
                <div
                  class="tmm_photo tmm_pic_808_0"
                  style="background: url({person.photoUrl}); margin-left: auto; margin-right:auto; background-size:cover !important;"
                />

                <div class="tmm_textblock">
                  <div class="tmm_names">
                    <span class="tmm_fname">{person.firstName} </span>
                    <span class="tmm_lname">{person.lastName}</span>
                  </div>
                  <div class="tmm_job">{person.job}</div>
                  <div class="tmm_desc" style="text-align:center">
                    <p>
                      {person.desc}
                    </p>
                  </div>
                  <div class="tmm_scblock" />
                </div>
              </div>
            {/each}

            <div style="clear:both;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>
