export let people = [
    {
        firstName: "Maciej",
        lastName: "Głuszak",
        job: "Dyrektor Zarządzający",
        photoUrl: "/photos/Maciej_Gluszak_Medium.png",
        desc: `Absolwent SGH. Przez 15 lat pracował w OMD, początkowo
                      jako planner, później jako kierownik działu
                      odpowiedzialnego za rozwój oprogramowania. W 2011 roku
                      założył McGluszak MacroIndustries. Jego konikiem jest
                      jakość kodu. Hobby: Dyskusje polityczne na facebooku.`,
    },
    {
        firstName: "Piotr",
        lastName: "Sendorwski",
        job: "Starszy Analityk-Programista",
        photoUrl: "/photos/team_senderowski.jpg",
        desc: `Absolwent MIESI w SGH. 4 lata pracował w OMD jako media
                    planner, gdzie planował i kupował kampanie reklamowe oraz
                    współtworzył strategie mediowe. Kolejne niespełna 2 lata
                    pracował w lubelskiej spółce eLeader (branża IT), w której
                    jako marketing manager odpowiadał za przygotowanie oraz
                    realizację strategii marketingowej. Obecnie analityk,
                    konsultant i software developer, przekładający swoje
                    doświadczenie na narzędzia ułatwiające funkcjonowanie m.in.
                    domów mediowych. Miłośnik narciarstwa i sportów wodnych.`,
    },
    {
        firstName: "Jakub",
        lastName: "Głuszak",
        job: "Programista",
        photoUrl: "/photos/522525_10200656862653631_1230557261_n.jpg",
        desc: `Absolwent lingwistyki stosowanąej na Uniwersytecie
                    Warszawskim. Przez ponad 10 lat pracował jako tłumacz,
                    przekładając głównie teksty informatyczne i marketingowe. W
                    2016 r. przekwalifikował się na programistę. W wolnym czasie
                    pisze i przekłada wiersze.`,
    },
    {
        firstName: "Paweł",
        lastName: "Gera",
        job: "Młodszy Programista",
        photoUrl: "/photos/pawel.gera_.jpg",
        desc: `Absolwent ekonomii oraz informatyki i ekonometrii z ponad 2
                    letnim doświadczeniem analitycznym. Sympatyk optymalizacji i
                    automatyzacji pracy w Excelu. W wolnym czasie lubi
                    podróżować i zdobywać nowe umiejętności, a także uprawiać
                    sporty wodne.`,
    },
    {
        firstName: "Kornel",
        lastName: "Jaśkiewicz",
        job: "Młodszy Programista",
        photoUrl: "/photos/adam-malysz.jpg",
        desc: `Absolwent ekonomii na Uniwersytecie Warszawskim. Laureat II
                    stopnia w XVII edycji konkursu na najlepsze prace
                    doktoranckie i magisterskie organizowanego przez Instytut
                    Pracy i Spraw Socjalnych. Od 2015 roku związany zawodowo z
                    branżą mediów i reklamy. Po pracy jest piłkarzem
                    występującym na pozycji skrzydłowego.`,
    },
    {
        firstName: "Ozjasz",
        lastName: "Goldberg",
        job: "Właściciel",
        photoUrl: "/photos/20180129_204857_001_01-1.jpg",
        desc: `Zamiast łowić myszy, siada na klawiaturze i przeszkadza. Nie
                    ma z niego pożytku.`,
    },
];
